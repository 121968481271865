<template>
  <v-simple-table>
    <thead>
      <tr>
        <th class="text-left" />
        <th class="text-left">
          {{ $t('permissions.show') }}
        </th>
        <th class="text-left">
          {{ $t('permissions.store') }}
        </th>
        <th class="text-left">
          {{ $t('permissions.update') }}
        </th>
        <th class="text-left">
          {{ $t('permissions.destroy') }}
        </th>
        <th class="text-left" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="permissionGroup in permissionsForGrid"
        :key="permissionGroup.resource"
      >
        <td>{{ $tc(`${permissionGroup.resource}.title`) }}</td>
        <RolePermissionCheckbox
          v-model="internalValue"
          :permission="permissionGroup.showPermission"
        />
        <RolePermissionCheckbox
          v-model="internalValue"
          :permission="permissionGroup.storePermission"
        />
        <RolePermissionCheckbox
          v-model="internalValue"
          :permission="permissionGroup.updatePermission"
        />
        <RolePermissionCheckbox
          v-model="internalValue"
          :permission="permissionGroup.destroyPermission"
        />
        <td>
          <div
            v-for="otherPermission in permissionGroup.otherPermissions"
            :key="otherPermission.identifier"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <span v-on="on">
                  <VCheckbox
                    v-model="internalValue"
                    :value="otherPermission.id"
                  />
                </span>
              </template>
              <span>{{ getOtherPermissionLanguage(otherPermission.identifier) }}</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import RolePermissionCheckbox from '@/modules/permissions/components/RolePermissionCheckbox';

export default {
  name: 'RoleGrid',
  components: {
    RolePermissionCheckbox,
  },
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    internalValue: [],
  }),
  computed: {
    permissionsForGrid() {
      return this.permissions.map(group => {
        return {
          showPermission: group.permissions.find(v => v.identifier === `${group.resource}.show`),
          storePermission: group.permissions.find(v => v.identifier === `${group.resource}.store`),
          updatePermission: group.permissions.find(v => v.identifier === `${group.resource}.update`),
          destroyPermission: group.permissions.find(v => v.identifier === `${group.resource}.destroy`),
          otherPermissions: group.permissions.filter(v => ![`${group.resource}.show`, `${group.resource}.store`, `${group.resource}.update`, `${group.resource}.destroy`].includes(v.identifier)),
          resource: group.resource,
        };
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    internalValue: {
      handler() {
        this.$emit('input', this.internalValue);
      },
    },
  },
  methods: {
    findPermission(permission, search) {
      return permission.permissions.find(obj => obj.identifier === `${permission.resource}.${search}`);
    },
    findNoneCrudPermissions(permission) {
      return permission.permissions.filter(obj =>
        obj.identifier !== `${permission.resource}.show` &&
        obj.identifier !== `${permission.resource}.store` &&
        obj.identifier !== `${permission.resource}.update` &&
        obj.identifier !== `${permission.resource}.destroy`,
      );
    },
    getOtherPermissionLanguage(identifier) {
      const [resource, permission] = identifier.split('.');
      return this.$t(`${resource}.permissions.${permission}`);
    },
  },
};
</script>
