<template>
  <k-field-group language-prefix="role.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="[{ name: $t('role.panels.general') },{ name: $t('role.panels.permissions') }]"
      v-on="$listeners"
    >
      <template #panel.0>
        <KTextField
          v-model="values.name"
          field="name"
          grid="col-sm-4 pr-2"
          required
        />
      </template>
      <template #panel.1>
        <RoleGrid
          v-model="values.permissions"
          :permissions="permissions"
        />
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import { convertSnakeToCamel } from '@/application/api/util/keyConverter.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import RoleGrid from '@/modules/permissions/components/RoleGrid';

export default {
  name: 'RoleForm',
  components: {
    RoleGrid,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
    permissionFetcher: {
      type: Function,
    },
  },
  data: () => ({
    permissions: [],
  }),
  async created() {
    const result = await this.permissionFetcher();
    this.permissions = result.data.data.map(({
      resource,
      permissions,
    }) => ({
      resource: convertSnakeToCamel(resource),
      permissions: permissions.map(({
        identifier,
        ...permission
      }) => ({
        identifier: convertSnakeToCamel(identifier),
        ...permission,
      })),
    }));
  },
};
</script>
