<template>
  <td>
    <v-tooltip
      v-if="permission"
      left
    >
      <template #activator="{ on }">
        <span v-on="on">
          <VCheckbox
            v-bind="{ ...$attrs }"
            :value="permission.id"
            v-on="$listeners"
          />
        </span>
      </template>
      <span>{{ $t('permissions.resource', getPermissionLanguageParameters(permission.identifier)) }}</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  name: 'RolePermissionCheckbox',
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    permission: {
      type: Object,
    },
  },
  data() {
    return {
      checkbox: this.value,
    };
  },
  watch: {
    value() {
      this.checkbox = this.value;
    },
    checkbox() {
      this.$emit('input', this.value);
    },
  },
  methods: {
    getPermissionLanguageParameters(identifier) {
      return {
        resource: this.$tc(`${identifier.split('.')[0]}.title`),
        permission: this.$t(`permissions.${identifier.split('.')[1]}`),
      };
    },
  },
};
</script>
